body, .modal-content {
  background: $bg-main;
  color: $white;
}

button {
  outline: none;
}

.btn:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none;
}

.row-reverse {
  @extend .row;
  flex-wrap: wrap-reverse;
}

.navbar {
  .bi-thermometer-half {
    margin-top: 3px;
  }

  .temperature,
  .power {
    font-size: $font-size-base * .75;
  }

  .power {
    .bi {
      margin-right: 4px;
    }

    .numbers {
      padding-top: 2px;
      padding-left: 2px;
    }
  }

  .divider {
    margin-top: 0.5rem !important;
  }
}

.divider {
  margin-top: 5px;
  background: rgb(79, 0, 145);
  background: linear-gradient(90deg, rgba(79, 0, 145, 0) 0%, rgba(255, 255, 255, .25) 20%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .25) 80%, rgba(0, 73, 140, 0) 100%);
  width: 100%;
  height: 1px;
}

.divider-90 {
  @extend .divider;
  transform: rotate(90deg);
}

main {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;

  .tab-title {
    margin-bottom: 10px;
  }

  .container {
    height: 100%;
  }

  .chart {
    margin-bottom: 25px;
  }

  .htc-card {
    color: $white;
    padding: 10px;
    text-align: center;
    
    svg {
      margin-bottom: 5px;
    }

    &.htc-bell {
      svg {
        margin-top: -1.3rem;
      }
    }
  }

  svg.info-icon {
    margin-top: 12px;
  }
}

.htc-button-like {
  box-shadow: rgb(22, 10, 105) 5px 5px 15px 0px;
  background: linear-gradient(145deg, rgba(32, 99, 150, 1) 0%, rgb(0, 62, 121) 100%);
  border-top: solid 1px rgba(255, 255, 255, 0.5);
  border-right: solid 1px rgba(255, 255, 255, 0.25);
  border-bottom: solid 1px rgba(255, 255, 255, 0.25);
  border-left: solid 1px rgba(255, 255, 255, 0.5);
  color: $white;
  
  &:hover,
  &:active,
  &:focus {
    color: $white;
    box-shadow: rgb(22, 10, 105) 5px 5px 15px 0px;
  }

  input[role="switch"] {
    transform: scale(1.5);
  }

  svg {
    margin: 5px inherit;
  }

  &.text-button {
    font-size: 125%;
  }
}

.htc-button {
  @extend .htc-button-like;

  &:hover,
  &:active,
  &:focus {
    border-color: white;
    color: $white;
  }

  &.active {
    color: $white;
    -webkit-animation: scale-up-button .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-button .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    box-shadow: rgb(22, 10, 105) 10px 10px 15px 0px;
  }
}

.htc-button-icon {
  @extend .htc-button;
  padding: 0;
}

footer {
  background: rgb(0, 62, 121);

  &.navbar {
    padding-top: 0;

    .divider {
      margin-top: 0 !important;
    }
  }

  .home-button {
    background: transparent;
    border: none;
    color: $white;
    opacity: 0.5;
    outline: none !important;
    box-shadow: none !important;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      -webkit-animation: scale-up-button .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: scale-up-button .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      opacity: 1;
    }

    &:focus:not(:active) {
      -webkit-animation: scale-up-button .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
      animation: scale-up-button .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
    }
  }
}

.navbar-toggler {
  background: transparent;
  border: none;
  color: $white;
  outline: none !important;
  box-shadow: none !important;
}

@include media-breakpoint-up(sm) {
  svg.dynamic {
    width: 32px;
    height: 32px;
  }
}

@include media-breakpoint-up(md) {
  svg.dynamic {
    width: 20px;
    height: 20px;
  }
}

.htc-check-input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background: white;
  display: inline-block;
  padding: 0px 5px;

  input.form-control {
    display: inline !important;
    width: auto !important;
    border: 0;
  }

  .htc-button-icon {
    box-shadow: none;
    margin-top: -4px;
  }
}

input, textarea, select {
  outline: none;
  outline-color: transparent;
  outline-style: none;

  &:focus {
    outline-color: transparent;
    outline-style: none;
    outline: none;
  }
}

.no-shadow {
  box-shadow: none;
}